import { useState, useEffect } from 'react';
import { getNews } from "../../api/news";

import "../../styles/NewsSection.css";
import NewsCaroussel from '../../components/NewsCaroussel';

const NewsSection = () => {
    const [news, setNews] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [gotFetchError, setGotFetchError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getNews();
                const data = response.data.sort((a, b) => new Date(b.datetimeCreation) - new Date(a.datetimeCreation));
                setNews(data);
            } catch (error) {
                console.error(error);
                setGotFetchError(true);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    if (isLoading || gotFetchError || news.length === 0) {
        return null;
    }

    return (
        <div id="news" className="news-section section">
            <h2 className="section-title">En ce moment !</h2>
            <div className="slider-container">
                <NewsCaroussel newsData={news} />
            </div>
        </div>
    );
};

export default NewsSection;