import { useEffect, useState } from 'react';
import arrow from '../assets/icons/bottom_arrow.svg';
import '../styles/BottomPageButton.css';

const BottomPageButton = () => {
    const [targetSection, setTargetSection] = useState('services');
    const [bottomArrowModifier, setBottomArrowModifier] = useState('to_bottom');
    const minScrollY = 5;

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > minScrollY) {
                setTargetSection('home');
                setBottomArrowModifier('to_top');
            } else {
                setTargetSection('services');
                setBottomArrowModifier('to_bottom');
            }
        }, { passive: true });
    });

    return (
        <a href={'#' + targetSection} className={'bottom_page_button bottom_page_button--' + bottomArrowModifier}>
            <img src={arrow} alt='Navigation button'></img>
        </a>
    );
};

export default BottomPageButton;