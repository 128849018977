import FacebookIcon from "../assets/images/facebook.png";
import InstagramIcon from "../assets/images/instagram.png";
import TwitterIcon from "../assets/images/twitter.png";
import DiscordIcon from "../assets/images/discord.png";
import MailIcon from "../assets/icons/mail.svg";
import PhoneIcon from "../assets/icons/phone.svg";
import "../styles/Footer.css";

const Footer = () => {
  const date = new Date();

  return (
    <footer id="footer" className="footer">
      <div className="footer__links">
        <div className="footer__links__contacts">
          <div className="footer__links__contacts__contact">
            <img
              src={MailIcon}
              className="footer__links__contacts__contact-icon"
              alt="Mail icon"
            ></img>
            <a
              href="mailto:contactgucant@gmail.com"
              className="footer__links__contacts__contact-content"
            >
              contactgucant@gmail.com
            </a>
          </div>
          <div className="footer__links__contacts__contact">
            <img
              src={PhoneIcon}
              className="footer__links__contacts__contact-icon"
              alt="Phone icon"
            ></img>
            <a
              href="tel:0631546066"
              className="footer__links__contacts__contact-content"
            >
              06.31.54.60.66
            </a>
          </div>
        </div>
        <div className="footer__links__navigation">
          <a href="#home" className="footer__links__navigation-item">
            Accueil
          </a>
          <a href="#news" className="footer__links__navigation-item">
            Actualités
          </a>
          <a href="#about-us" className="footer__links__navigation-item">
            À propos
          </a>
          <a href="#services" className="footer__links__navigation-item">
            Services
          </a>
        </div>
        <div className="footer__links__social_medias">
          <div className="footer__links__social_medias__media">
            <a
              href="https://www.facebook.com/gucantinfo/"
              target="_blank"
              rel="noreferrer"
              className="footer__links__social_medias__media-link"
            >
              Facebook
            </a>
            <img
              src={FacebookIcon}
              className="footer__links__social_medias__media-logo"
              alt="Facebook logo"
            ></img>
          </div>
          <div className="footer__links__social_medias__media">
            <a
              href="https://www.instagram.com/gucantinfo/"
              target="_blank"
              rel="noreferrer"
              className="footer__links__social_medias__media-link"
            >
              Instagram
            </a>
            <img
              src={InstagramIcon}
              className="footer__links__social_medias__media-logo"
              alt="Instagram logo"
            ></img>
          </div>
          <div className="footer__links__social_medias__media">
            <a
              href="https://www.facebook.com/gucantinfo/"
              target="_blank"
              rel="noreferrer"
              className="footer__links__social_medias__media-link"
            >
              Twitter
            </a>
            <img
              src={TwitterIcon}
              className="footer__links__social_medias__media-logo"
              alt="Facebook logo"
            ></img>
          </div>
          <div className="footer__links__social_medias__media">
            <a
              href="https://www.facebook.com/gucantinfo/"
              target="_blank"
              rel="noreferrer"
              className="footer__links__social_medias__media-link"
            >
              Discord
            </a>
            <img
              src={DiscordIcon}
              className="footer__links__social_medias__media-logo"
              alt="Facebook logo"
            ></img>
          </div>
        </div>
      </div>
      <div className="footer__legal_infos">
        © {date.getFullYear()} Gucant Informatique | EI - GUCANT INFORMATIQUE -
        29380 Bannalec | Auto-Entrepreneur au régime de la micro-entreprise
        <br />
        SIRET 91363686600034 - APE 9511Z | TVA non applicable, art. 293 B du CGI
      </div>
    </footer>
  );
};

export default Footer;
