import Slider from "./Slider"
import { getImageSrc } from "../api/news";

import { ReactComponent as Arrow } from "../assets/icons/bottom_arrow.svg";
import "../styles/NewsCaroussel.css";
import DefaultImage from "../assets/images/logo.png";

const NewsCaroussel = ({ newsData }) => {
    return (
        <Slider transitionTime={0.8} previousButton={<Arrow />} nextButton={<Arrow />}>
            {newsData.map((news, index) => (
                <div key={index} className="news">
                    <div className={`news__image-wrapper ${!news.fileName ? "no-image" : ""}`}>
                        <img src={news.fileName ? getImageSrc(news.fileName) : DefaultImage}
                            alt={news.alternativeText}
                            loading="lazy"
                        />
                    </div>
                    <div className="news__content-container">
                        <h3 className="news__content-title">{news.titre}</h3>
                        <div className="news__content-text" dangerouslySetInnerHTML={{ __html: news.texte }} />
                    </div>
                </div>
            ))}
        </Slider>
    );
}

export default NewsCaroussel;