import emailjs from 'emailjs-com';
import ScaleLoader from "react-spinners/ScaleLoader";
import { useRef, useState } from 'react';
import '../../styles/ContactSection.css';

const ContactSection = () => {
    const [loading, setLoading] = useState(false);
    const form = useRef();

    const loaderStyle = `
    position: absolute;
    pointer-events: none;
  `;

    const sendMail = (e) => {
        e.preventDefault();

        const submitBtn = document.getElementById('submit-contact');
        submitBtn.classList.add('waiting');
        submitBtn.setAttribute('disabled', '');

        setLoading(true);

        emailjs.sendForm('service_c8aj1bd', 'template_rlkfakm', form.current, 'BeKwBtWkEEjKn81O_')
            .then(() => {
                submitBtn.classList.add('success');
                submitBtn.nextElementSibling.innerHTML = 'Envoyé !';
            }, () => {
                submitBtn.classList.add('failed');
                submitBtn.nextElementSibling.innerHTML = 'Réessayez';
                submitBtn.removeAttribute('disabled');
            }).finally(() => {
                submitBtn.classList.remove('waiting');
                setLoading(false);
            });
    };

    return (
        <div id='contact' className='section contact'>
            <h2 className='contact-title'>Nous contacter</h2>
            <div className='contact-text'>
                Envie de nouveauté, besoin de réparation, de services ou simplement
                un petit mot à l'intention du gérant ?
            </div>
            <div className='contact__card'>
                <form ref={form} onSubmit={sendMail} className='contact__card__form'>
                    <h3>Envoyez votre requête !</h3>
                    <div className='contact__card__inputs'>
                        <div className='contact__card-input'>
                            <input name='name' type='text' required />
                            <label htmlFor='name'>Nom</label>
                        </div>
                        <div className='contact__card-input'>
                            <input name='phone' type='text' required />
                            <label htmlFor='phone'>Téléphone</label>
                        </div>
                        <div className='contact__card-input'>
                            <input name='email' type='email' required />
                            <label htmlFor='email'>Email</label>
                        </div>
                        <div className='contact__card-input'>
                            <input name='subject' type='text' required />
                            <label htmlFor='subject'>Sujet</label>
                        </div>
                    </div>
                    <div className='contact__card-input'>
                        <textarea name='message' maxLength={750} required></textarea>
                        <label htmlFor='message'>Votre message</label>
                    </div>
                    <div className='contact__card-submit'>
                        <input id='submit-contact' type='submit' value='' />
                        <span>Envoyer</span>
                        <ScaleLoader loading={loading} color='white' width={4} height={25} margin={5} css={loaderStyle} />
                    </div>
                </form>
                <div className='contact__card__infos'>
                    <h3>Informations</h3>
                    <div className='contact__card__infos__contents'>
                        <div className='contact__card__infos__info_group'>
                            <div className='contact__card__infos__info_group-title'>
                                Adresse
                            </div>
                            <div className='contact__card__infos__info_group-content'>
                                <a href="https://goo.gl/maps/agkzgw1XvZJCSWZv5" target='_blank' rel='noreferrer'>
                                    3 bis rue Leuriou
                                    <br />29300 QUIMPERLE
                                </a>
                            </div>
                        </div>
                        <div className='contact__card__infos__info_group'>
                            <div className='contact__card__infos__info_group-title'>
                                E-mail
                            </div>
                            <div className='contact__card__infos__info_group-content'>
                                <a href='mailto:contactgucant@gmail.com'>contactgucant@gmail.com</a>
                            </div>
                        </div>
                        <div className='contact__card__infos__info_group'>
                            <div className='contact__card__infos__info_group-title'>
                                Téléphone
                            </div>
                            <div className='contact__card__infos__info_group-content'>
                                <a href='tel:0631546066'>06.31.54.60.66</a>
                            </div>
                        </div>
                        <div className='contact__card__infos__info_group'>
                            <div className='contact__card__infos__info_group-title'>
                                Réseaux
                            </div>
                            <div className='contact__card__infos__info_group-content'>
                                <a href='https://www.facebook.com/gucantinfo/' target='_blank' rel='noreferrer'>Facebook</a>
                                <br />
                                <a href='https://www.instagram.com/gucantinfo/' target='_blank' rel='noreferrer'>Instagram</a>
                                <br />
                                <a href='https://www.twitter.com/Gucantinfo' target='_blank' rel='noreferrer'>Twitter</a>
                                <br />
                                <a href='https://discord.com/invite/3SxMFp2FWU' target='_blank' rel='noreferrer'>Discord</a>
                            </div>
                        </div>
                        <div className="contact__card__infos__info_group --hours">
                            <div className="contact__card__infos__info_group-title">
                                Horaires
                            </div>
                            <div className="contact__card__infos__info_group-content">
                                <div className="spaced-line">
                                    Ouvert du Mardi au Samedi
                                </div>
                                <div className="spaced-line">
                                    De 9h à 12h et de 13h à 18h
                                </div>
                                <div className="spaced-line">
                                    Dimanche et Lundi sur RDV
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactSection;