import HandymanIcon from "../../assets/icons/handyman.svg";
import DashboardIcon from "../../assets/icons/dashboard.svg";
import SchoolIcon from "../../assets/icons/school.svg";
import WebIcon from "../../assets/icons/web.svg";
import NeonCard from "../../components/NeonCard";
import "../../styles/ServicesSection.css";

const ServicesSection = () => {
  return (
    <div id="services" className="services section">
      <div className="section-title">Nos services</div>

      <div className="services__cards">
        <NeonCard
          text="Maintenance"
          icon={HandymanIcon}
          description="Réparation / Entretien"
          pricing="35€/h"
          disclaimer="Aussi à domicile !"
        />
        <NeonCard
          text="Montage"
          icon={DashboardIcon}
          description="Conception sur-mesure"
          pricing="60€"
          disclaimer="(montage)"
        />
        <NeonCard
          text="Formation"
          icon={SchoolIcon}
          description="Dispense de cours personnalisés"
          pricing="25€/h"
        />
        <NeonCard
          text="Site web"
          icon={WebIcon}
          description="Création de site web"
          pricing="500€"
          disclaimer={"Sur devis"}
        />
      </div>

      <div className="services__tarifs">
        <p>Consultez notre grille tarifaire complète</p>
        <a
          href="https://api.gucant.fr/doc/Tarifs.pdf"
          target="_blank"
          rel="noreferrer"
          className="light-button"
        >
          Voir les tarifs
        </a>
      </div>
    </div>
  );
};

export default ServicesSection;
