import { useEffect, useState } from "react";

import "../styles/Slider.css";

const Slider = ({ children, previousButton, nextButton, transitionTime = 0 }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [previousIndex, setPreviousIndex] = useState(null);

    const activeClass = "active";
    const closingClass = "closing";

    document.documentElement.style.setProperty("--slider-transition-time", `${transitionTime}s`);

    const goToPreviousSlide = () => {
        setPreviousIndex(activeIndex);
        setActiveIndex((prevIndex) => prevIndex === 0 ? children.length - 1 : --prevIndex);
    }

    const goToNextSlide = () => {
        setPreviousIndex(activeIndex);
        setActiveIndex((prevIndex) => ++prevIndex % children.length);
    }

    useEffect(() => {
        if (previousIndex !== null) {
            const timer = setTimeout(() => {
                setPreviousIndex(null);
            }, transitionTime * 1000);

            return () => clearTimeout(timer);
        }
    }, [previousIndex, transitionTime]);

    return (
        <div className="slider">
            <div className="slider__btn-container">
                {<button className="slider__previous" onClick={goToPreviousSlide}>{previousButton || "Previous"}</button>}
            </div>
            <div className="slider__container">
                {children.map((child, index) => (
                    <div key={index} className={`slider__item-wrapper ${activeIndex === index ? activeClass : ""} ${previousIndex === index ? closingClass : ""}`}>
                        {child}
                    </div>
                ))}
            </div>
            <div className="slider__btn-container">
                {<button className="slider__next" onClick={goToNextSlide}>{nextButton || "Next"}</button>}
            </div>
        </div>
    );
};

export default Slider;