import { useState } from 'react';
import '../styles/NeonCard.css';

const NeonCard = ({ text, icon, description, pricing, disclaimer }) => {
    const [isFlipped, setIsFlipped] = useState(false);

    const handleClick = () => {
        setIsFlipped(!isFlipped);
    }

    return (
        <div className={'card' + (isFlipped ? ' flipped' : '')}
            onClick={handleClick}>
            <span></span>
            <div className='card__inner'>
                <div className='card__inner__front'>
                    <img src={icon} className='neon_icon' alt={text + ' icon'}></img>
                    <h3 className='services__cards-card-title'>
                        {text}
                    </h3>
                </div>
                <div className='card__inner__back'>
                    <div className='card-description'>
                        {description}
                    </div>
                    <div className='card-from'>
                        À partir de
                    </div>
                    <div className='card-price'>
                        {pricing}
                    </div>
                    <div className='card-disclaimer'>
                        {disclaimer}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default NeonCard;