import axios from 'axios';

function getApiUrl() {
    if (process.env.NODE_ENV === 'production') {
        return 'https://api.gucant.fr';
    }

    return 'http://localhost:3001';
}

export async function getNews() {
    return axios.get(`${getApiUrl()}/news`);
}

export function getImageSrc(filename) {
    return `${getApiUrl()}/img/${filename}`;
}