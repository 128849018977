import { useEffect } from 'react';
import '../styles/SideMenu.css';

const SideMenu = ({ show }) => {
    useEffect(() => {
        if (show) {
            document.getElementById('side_menu').classList.add('open');
            document.body.style.overflow = 'hidden';
        } else {
            document.getElementById('side_menu').classList.remove('open');
            document.body.style.overflow = 'auto';
        }
    }, [show]);

    const closeMenu = () => {
        document.getElementsByClassName('hamburger-react')[0].click();
    }

    return (
        <div id='side_menu'>
            <nav>
                <ul>
                    <li><a onClick={closeMenu} href='#home'>Accueil</a></li>
                    <li><a onClick={closeMenu} href='#news'>Actualités</a></li>
                    <li><a onClick={closeMenu} href='#about-us'>À propos</a></li>
                    <li><a onClick={closeMenu} href='#services'>Services</a></li>
                    <li><a onClick={closeMenu} href='#contact'>Contact</a></li>
                </ul>
            </nav>
        </div>
    );
};

export default SideMenu;