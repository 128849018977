import SideMenu from "../../components/SideMenu";
import { Sling as Hamburger } from "hamburger-react";
import { useState } from "react";
import Logo from "../../assets/images/logo.png";
import "../../styles/MainSection.css";

const MainSection = () => {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  return (
    <div id="home" className="section --first home">
      <header>
        <nav className="home__left_menu">
          <a className="home__nav-button home__left_menu-item" href="#news">
            Actualités
          </a>
          <a className="home__nav-button home__left-menu-item" href="#about-us">
            À propos
          </a>
        </nav>
        <div className="home__logo_container">
          <img
            src={Logo}
            alt="Logo"
            className="home__logo_container-logo"
          ></img>
        </div>
        <div className="home__right_menu">
          <a className="home__nav-button home__left_menu-item" href="#services">
            Services
          </a>
          <a
            href="#contact"
            className="light-button home__nav-button home__right_menu-contact-button"
          >
            Contacter
          </a>
        </div>

        <SideMenu show={isSideMenuOpen} />
        <Hamburger
          label="Show menu"
          rounded
          toggled={isSideMenuOpen}
          toggle={setIsSideMenuOpen}
        />
      </header>

      <div className="home__main_content">
        <h1 className="home__main_content-title">Gucant Informatique</h1>
        <div className="home__main_content-subtitle">
          Jeune entreprise informatique Quimperlé et alentours
        </div>
        <div className="home__main_content-text">
          Je vous propose mes services en création sur-mesure de PC, réparation
          et entretien (PC, téléphonie, tablette). Je dispense également des
          séances de formation numérique tout-public.
        </div>
      </div>

      <div className="home__main_buttons">
        <a
          href="#about-us"
          className="light-button home__main_buttons-see_more"
        >
          En savoir plus
        </a>
        <a
          href="#services"
          className="light-button home__main_buttons-services"
        >
          Nos services
        </a>
      </div>
    </div>
  );
};

export default MainSection;
