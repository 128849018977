import BottomPageButton from '../components/BottomPageButton';
import MainSection from './home_sections/MainSection';
import AboutUsSection from './home_sections/AboutUsSection';
import ServicesSection from './home_sections/ServicesSection';
import ContactSection from './home_sections/ContactSection';
import Footer from './Footer';
import NewsSection from './home_sections/NewsSection';

import '../styles/App.css';

function App() {
  return (
    <div className='App'>
      <BottomPageButton />

      <MainSection />

      <NewsSection />

      <AboutUsSection />

      <ServicesSection />

      {/* <TwitchSection /> */}

      <ContactSection />

      {/* <EasterEgg /> */}

      <Footer />
    </div>
  );
}

export default App;