import Tilt from 'react-vanilla-tilt';
import AboutUsImage from '../../assets/images/gucant_pensif.jfif';
import FacebookIcon from '../../assets/images/facebook.png';
import InstagramIcon from '../../assets/images/instagram.png';
import TwitterIcon from '../../assets/images/twitter.png';
import DiscordIcon from '../../assets/images/discord.png';
import '../../styles/AboutUsSection.css'

const AboutUsSection = () => {
    return (
        <div id='about-us' className='section about_us'>
            <h2 className='about_us-title'>À propos de Gucant Informatique</h2>
            <div className='about_us__content'>
                <Tilt className='about_us__content-tilt'>
                    <img className='about_us__content-image' src={AboutUsImage} alt='Gucant assis sur son bureau, de dos' loading="lazy"></img>
                </Tilt>
                <div className='about_us__right_content'>
                    <div className='about_us__right_content-text'>
                        <q>
                            La création de cette entreprise était devenue pour moi une évidence.
                            Voulant l'initier en 2020, j'ai décidé de reporter son ouverture des suites de la Covid-19.
                            J'ai par conséquent suivi un plan d'action orchestré par la BGE duquel j'en ai peaufiné les recherches et résultats.
                            <br /><br />
                            Depuis petit je touche à l'informatique. J'ai été initié par mon beau-père qui tenait une boutique dans ce domaine.
                            C'est depuis ce temps que j'en ai fait ma passion.
                            <br /><br />
                            J'ai commencé à me renseigner sur l'<q>hardware</q> (pièces et utilisations de celle-ci, partie physique de la machine)
                            jusqu'à être assez instruit pour monter mon propre PC.
                            Depuis, je ne cesse de me renseigner sur les nouveaux produits et nouvelles technologies.
                            Je me tiens informé de chaque nouveauté et les teste.
                            <br /><br />
                            C'est pourquoi je vous propose aujourd'hui <a href='#services' className='about_us_right_content-services'>mes services</a> dans
                            le domaine de mes compétences à des tarifs défiant toute concurrence.
                        </q>
                    </div>
                    <p>Rejoignez-nous !</p>
                    <div className='about_us__content__social_medias'>
                        <a href='https://www.facebook.com/gucantinfo/' target='_blank' rel='noreferrer' className='social_media_badge material-shadow'>
                            <img src={FacebookIcon} alt='Facebook logo'></img>
                        </a>
                        <a href='https://www.instagram.com/gucantinfo/' target='_blank' rel='noreferrer' className='social_media_badge material-shadow'>
                            <img src={InstagramIcon} alt='Instagram logo'></img>
                        </a>
                        <a href='https://www.twitter.com/Gucantinfo' target='_blank' rel='noreferrer' className='social_media_badge material-shadow'>
                            <img src={TwitterIcon} alt='Twitter logo'></img>
                        </a>
                        <a href='https://discord.com/invite/3SxMFp2FWU' target='_blank' rel='noreferrer' className='social_media_badge material-shadow'>
                            <img src={DiscordIcon} alt='Discord logo'></img>
                        </a>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default AboutUsSection;
